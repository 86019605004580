<template>
  <div class="company-root-container">
    <div class="company-main-container">
      <div class="company-row">
        <span style="font-size: 23px; font-weight: bold"
          >添加一行实体企业信息</span
        >
        <img
          @click="addCompany"
          :src="icons.create"
          style="width: 23px; height: 23px; cursor: pointer"
        />
      </div>
      <p style="margin-right: 150px">
        注：实体企业指雇主品牌旗下涵盖的工商登记实体，一个雇主品牌可以绑定多个实体企业。实体企业是游用工服务协议和发票接收的主体，所以您应当创建至少一个完整的实体企业信息
      </p>
    </div>
    <div>
      <company-item
        v-for="item in companyList"
        :key="item.company_id"
        :data-source="item"
      />
    </div>
    <company-drawer ref="companyDrawer" @refresh-company="refreshCompany" />
    <company-add-success-modal ref="companyAddSuccessModal" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
import CompanyItem from "../Item/CompanyItem";
import CompanyDrawer from "../Drawer/EmployerCompanyDrawer";
import CompanyAddSuccessModal from "../Modal/CompanyAddSuccessModal";
export default {
  components: {
    "company-add-success-modal": CompanyAddSuccessModal,
    "company-drawer": CompanyDrawer,
    "company-item": CompanyItem,
  },
  data() {
    return {
      icons: icon,
      companyList: [],
    };
  },
  mounted() {
    this.getCompanyListAction({
      employer_id: localStorage.getItem("employer_id"),
    })
      .then((res) => {
        this.companyList = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      getCompanyListAction: "getCompanyList",
    }),
    addCompany() {
      this.$refs.companyDrawer.open();
    },
    refreshCompany(company_id) {
      this.getCompanyListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.companyList = res;
          this.$refs.companyAddSuccessModal.open(company_id);
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.company-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.company-main-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 20px 20px 50px;
  background-color: #fff;
  border-radius: 14px;
  margin-bottom: 20px;
}
.company-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>