<template>
  <div class="service-root-container">
    <span class="service-title">服务协议</span>
    <slide-tabber
      style="align-self: center; margin-bottom: 20px"
      :tabs="tabs"
      @on-change="onBarChange"
    />
    <div
      class="service-company-container"
      v-for="company in serviceList"
      :key="company.id"
    >
      <div class="service-company-row">
        <my-avatar :name="company.name" :img_url="company.img_url" />
        <span style="margin-left: 10px; font-size: 15px; font-weight: bold">{{
          company.name
        }}</span>
      </div>
      <service-item
        v-for="service in company.children"
        :key="service.id"
        :data-source="service"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
import ServiceItem from "../Item/ServiceItem";
import MyAvatar from "../../../components/MyAvatar";
import SlideTabber from "../../../components/SlideTabbar";
export default {
  components: {
    "my-avatar": MyAvatar,
    "slide-tabber": SlideTabber,
    "service-item": ServiceItem,
  },
  data() {
    return {
      icons: icon,
      currentIndex: 0,
      tabs: [
        {
          name: "生效中的",
          count: 0,
        },
        {
          name: "待签署的",
          count: 0,
        },
        {
          name: "已过期的",
          count: 0,
        },
      ],
      service_wait_list: [],
      service_valid_list: [],
      service_over_list: [],
    };
  },
  mounted() {
    this.employerGetServiceAction({
      employer_id: localStorage.getItem("employer_id"),
    })
      .then((res) => {
        // type 1=待签署 2=生效中 3=已过期
        this.service_wait_list = [];
        this.service_valid_list = [];
        this.service_over_list = [];
        for (let item of res.data.list) {
          let arr = [];
          if (item.type == 1) {
            arr = this.service_wait_list;
          } else if (item.type == 2) {
            arr = this.service_valid_list;
          } else {
            arr = this.service_over_list;
          }

          let result = arr.find(
            (service) => item.company_id == service.company_id
          );
          if (result) {
            result.children.push({
              id: item.id,
              state: item.type,
              name: item.contract_name,
              year: item.end_time
                ? moment(item.end_time).diff(moment(item.start_time), "years")
                : "-",
              start_time: moment(item.start_time).format("YYYY-MM-DD"),
              end_time: item.end_time
                ? moment(item.end_time).format("YYYY-MM-DD")
                : "-",
              view_url: item.view_url,
              count: item.count,
            });
          } else {
            arr.push({
              company_id: item.company_id,
              name: item.company_name,
              img_url: item.company_img_url,
              children: [
                {
                  id: item.id,
                  state: item.type,
                  name: item.contract_name,
                  year: item.end_time
                    ? moment(item.end_time).diff(
                        moment(item.start_time),
                        "years"
                      )
                    : "-",
                  start_time: moment(item.start_time).format("YYYY-MM-DD"),
                  end_time: item.end_time
                    ? moment(item.end_time).format("YYYY-MM-DD")
                    : "-",
                  view_url: item.view_url,
                  count: item.count,
                },
              ],
            });
          }
        }
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      employerGetServiceAction: "employerGetService",
    }),
    onBarChange(index) {
      this.currentIndex = index;
    },
  },
  computed: {
    serviceList: function () {
      if (this.currentIndex == 0) {
        return this.service_valid_list;
      } else if (this.currentIndex == 1) {
        return this.service_wait_list;
      } else {
        return this.service_over_list;
      }
    },
  },
};
</script>

<style scoped>
.service-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 20px;
  background-color: #fff;
  border-radius: 14px;
}
.service-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 20px;
}
.service-company-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f2f2f2;
  margin-bottom: 10px;
}
.service-company-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 20px;
}
</style>