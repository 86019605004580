<template>
  <Modal v-model="isShowModal" :footer-hide="true" :closable="false">
    <div class="company-add-success-modal-root-container">
      <div class="page-title" style="padding-top: 50px">添加成功</div>
      <div class="company-add-success-modal-tip-text">
        为保证后续业务顺畅，您可以立即进入该企业主页，进行企业认证，完善银行卡、联系地址、联系人等信息。
      </div>
      <Button
        type="primary"
        shape="circle"
        size="large"
        style="width: 50%"
        @click="goCompany"
        >立即完善</Button
      >
      <span
        style="
          color: #6f87cd;
          font-size: 13px;
          font-weight: 500;
          margin-top: 30px;
          cursor: pointer;
        "
        @click="close"
        >稍后再说</span
      >
    </div>
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      isShowModal: false,
      company_id: 0,
    };
  },
  methods: {
    open(company_id) {
      this.company_id = company_id;

      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
    goCompany() {
      this.$router.push({
        name: "employer-company",
        params: {
          company_id: this.company_id,
        },
      });
    },
  },
};
</script>

<style scoped>
.company-add-success-modal-root-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
}
.company-add-success-modal-tip-text {
  width: 60%;
  font-size: 13px;
  font-weight: 500;
  color: #737875;
  text-align: center;
  align-self: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
</style>