<template>
  <my-drawer ref="companyDrawer" title="添加实体企业信息">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-single-image
          class="employer-form-image"
          ref="formImage"
          :before-upload="handleBeforeUpload"
          :img-url="companyForm.img_url"
        ></my-single-image>

        <my-input
          class="employer-form-item"
          ref="formName"
          title="名称"
          v-model="companyForm.name"
          :validate="nameValidate"
        />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import * as oss from "../../../utils/ossHelper";
import MyDrawer from "../../../components/MyDrawer";
import MyInput from "../../../components/Form/MyInput";
import MySingleImage from "../../../components/Form/MySingleImage";
import { mapActions } from "vuex";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-input": MyInput,
    "my-single-image": MySingleImage,
  },
  data() {
    return {
      companyForm: {
        employer_id: localStorage.getItem("employer_id"),
        img_url: "",
        name: "",
      },
    };
  },
  methods: {
    ...mapActions({
      companyCreateAction: "companyCreate",
    }),
    open() {
      this.$refs.companyDrawer.open();
    },
    submit() {
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          this.companyCreateAction(this.companyForm)
            .then((res) => {
              this.companyForm = {
                employer_id: localStorage.getItem("employer_id"),
                img_url: "",
                name: "",
              };
              // this.$Message.success("添加企业成功");
              this.$emit("refresh-company", res.company_id);
              this.$refs.companyDrawer.close();
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 图片上传 */
    handleBeforeUpload(file) {
      this.$Spin.show();
      oss.upload(file, "company").then((result) => {
        this.companyForm.img_url = result.fullPath;
        this.$Spin.hide();
      });
      return false;
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.companyForm.name == "") {
          reject("请填写实体企业名称");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.employer-form-image {
  width: 100px;
  height: 118px;
}
</style>
