<template>
  <div class="employer-desc-root-container">
    <img @click="edit" class="employer-desc-edit" :src="icons.edit" />
    <span class="employer-desc-title">雇主品牌描述</span>
    <p>{{ desc }}</p>
    <employer-desc-drawer
      @refresh-desc="refreshDesc"
      ref="employerDescDrawer"
    />
  </div>
</template>

<script>
import EmployerDescDrawer from "../Drawer/EmployerDescDrawer";
import * as icon from "../../../common/icon";
import { mapActions } from "vuex";
export default {
  components: {
    "employer-desc-drawer": EmployerDescDrawer,
  },
  data() {
    return {
      icons: icon,
      desc: "",
      employer_id: 0,
    };
  },
  mounted() {
    this.employer_id = localStorage.getItem("employer_id");
    this.refreshDesc();
  },
  methods: {
    ...mapActions({
      employerGetDescriptionAction: "employerGetDescription",
    }),
    edit() {
      this.$refs.employerDescDrawer.open(this.desc);
    },
    refreshDesc() {
      this.employerGetDescriptionAction({
        employer_id: this.employer_id,
      })
        .then((res) => {
          this.desc = res.description;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.employer-desc-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 40px;
  background-color: #fff;
  border-radius: 14px;
}
.employer-desc-title {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 40px;
}
.employer-desc-edit {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
</style>