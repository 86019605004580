<template>
  <my-drawer ref="memberDrawer" title="邀请成员">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-input
          ref="formEmail"
          title="邮箱"
          v-model="email"
          :validate="emailValidate"
        />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import { mapActions } from "vuex";
import MyInput from "../../../components/Form/MyInput";
import MyDrawer from "../../../components/MyDrawer";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-input": MyInput,
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    ...mapActions({
      employerSendMailAction: "employerSendMail",
    }),
    open() {
      this.$refs.memberDrawer.open();
    },
    submit() {
      Promise.all([this.$refs.formEmail.doValidate()])
        .then(() => {
          this.employerSendMailAction({
            employer_id: localStorage.getItem("employer_id"),
            email: this.email,
          }).then((res) => {
            this.email = "";
            this.$Message.success("邀请成功");
            this.$refs.memberDrawer.close();
          });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 表单验证 */
    emailValidate(value) {
      return new Promise((resolve, reject) => {
        if (
          !/^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/.test(this.email)
        ) {
          reject("请填写合法的邮箱地址");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
</style>