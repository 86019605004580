<template>
  <div @click="gotoCompany" class="company-item-root-container">
    <my-avatar
      style="margin-right:20px;cursor: pointer;"
      :size="50"
      :name="dataSource.name"
      :img_url="dataSource.img_url"
    />
    <span class="company-item-title">{{dataSource.name}}</span>
    <div style="flex:1"></div>
    <div class="company-row" v-if="dataSource.state == 2">
      <img :src="icons.pass" />
      <span style="font-size:12px;color:#2b2b2b;margin-left:10px;">已通过企业认证</span>
    </div>
    <div class="company-row" v-else>
      <img :src="icons.warning" />
      <span style="font-size:12px;color:#808080;margin-left:10px;">未完成企业认证</span>
    </div>
    <img :src="icons.rightArrow" style="margin-left:60px" />
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
import MyAvatar from "../../../components/MyAvatar";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      icons: icon,
    };
  },
  methods: {
    gotoCompany() {
      this.$router.push({
        name: "employer-company",
        params: {
          company_id: this.dataSource.company_id,
        },
      });
    },
  },
};
</script>

<style>
.company-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border-radius: 14px;
  padding: 10px 50px;
  margin-top: 10px;
  cursor: pointer;
}
.company-item-title {
  font-size: 15px;
  font-weight: bold;
}
.company-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>