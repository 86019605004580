<template>
  <div class="employer-base-root-container">
    <img @click="edit" class="employer-base-edit" :src="icons.edit" />
    <div
      class="employer-base-image"
      :style="{
        'background-image':
          'url(' +
          (baseInfo.img_url ? baseInfo.img_url : icons.employerAvatar) +
          ')',
      }"
    ></div>
    <span class="employer-base-name">{{ baseInfo.name }}</span>
    <Divider />
    <div class="employer-base-detail-container">
      <div class="employer-base-detail">
        <img class="employer-base-detail-img" :src="icons.business" />
        <span>{{ baseInfo.business }}</span>
      </div>
      <div class="employer-base-detail">
        <img class="employer-base-detail-img" :src="icons.scale" />
        <span>{{ baseInfo.scale }}</span>
      </div>
      <div class="employer-base-detail">
        <img class="employer-base-detail-img" :src="icons.position" />
        <span>{{ baseInfo.position }}</span>
      </div>
      <div class="employer-base-detail">
        <img class="employer-base-detail-img" :src="icons.count" />
        <span>{{ baseInfo.count }}</span>
      </div>
    </div>
    <employer-drawer ref="employerDrawer" @refresh-employer-info="getInfo" />
  </div>
</template>

<script>
import EmployerDrawer from "../Drawer/EmployerDrawer";
import * as icon from "../../../common/icon";
import { mapActions } from "vuex";
export default {
  components: {
    "employer-drawer": EmployerDrawer,
  },
  data() {
    return {
      icons: icon,
      employer_id: -1,
      baseInfo: {
        img_url: null,
        name: "礼信年年餐饮服务集团",
        business: "餐饮  服务业",
        scale: "集团公司（非上市）",
        position: "北京 山东 浙江",
        count: "5000-9999人",
      },
    };
  },
  mounted() {
    this.employer_id = localStorage.getItem("employer_id");

    this.getInfo();
  },
  methods: {
    ...mapActions({
      employerGetBasicAction: "employerGetBasic",
    }),
    edit() {
      this.$refs.employerDrawer.open(this.employer_id);
    },
    getInfo() {
      this.employerGetBasicAction({
        employer_id: this.employer_id,
      })
        .then((res) => {
          this.baseInfo = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.employer-base-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 14px;
}
.employer-base-image {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  border: 1px solid #eeeeee;
}
.employer-base-name {
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
}
.employer-base-detail-container {
  display: flex;
  flex-direction: column;
}
.employer-base-detail {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  font-weight: bold;
}
.employer-base-detail-img {
  width: 23px;
  height: 23px;
  margin-right: 25px;
}
.employer-base-edit {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 23px;
  height: 23px;
  cursor: pointer;
}
</style>