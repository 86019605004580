<template>
  <my-drawer ref="employerDescDrawer" title="编辑雇主描述">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-textarea
          class="employer-desc-form-item"
          ref="formDesc"
          title="描述"
          :rows="10"
          v-model="desc"
        />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import { mapActions } from "vuex";
import MyTextArea from "../../../components/Form/MyTextArea";
import MyDrawer from "../../../components/MyDrawer";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-textarea": MyTextArea,
  },
  data() {
    return {
      desc: "",
      employer_id: 0,
    };
  },
  methods: {
    ...mapActions({
      employerSetDescriptionAction: "employerSetDescription",
    }),
    open(desc) {
      this.desc = desc;
      this.employer_id = localStorage.getItem("employer_id");
      this.$refs.employerDescDrawer.open();
    },
    submit() {
      this.employerSetDescriptionAction({
        employer_id: this.employer_id,
        description: this.desc,
      })
        .then((res) => {
          this.$Message.success("保存描述成功");
          this.$emit("refresh-desc");
          this.$refs.employerDescDrawer.close();
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
/* .employer-desc-form-item {
} */
</style>