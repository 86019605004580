<template>
  <div class="member-root-container">
    <div class="member-header-container">
      <span class="member-title">成员列表</span>
      <span @click="addMember" style="color: #4f90d6; cursor: pointer"
        >邀请成员</span
      >
    </div>
    <div class="member-main-container">
      <my-avatar
        style="margin-right: 20px; cursor: pointer"
        v-for="item in memberList"
        :size="50"
        :key="item.id"
        :name="item.name"
        :img_url="item.img_url"
      />
    </div>
    <member-drawer ref="memberDrawer" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import MyAvatar from "../../../components/MyAvatar";
import MemberDrawer from "../Drawer/EmployerMemberDrawer";
export default {
  components: {
    "my-avatar": MyAvatar,
    "member-drawer": MemberDrawer,
  },
  data() {
    return {
      employer_id: 0,
      memberList: [],
    };
  },
  mounted() {
    this.employer_id = localStorage.getItem("employer_id");
    this.employerGetUserListAction({
      employer_id: this.employer_id,
    })
      .then((res) => {
        this.memberList = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      employerGetUserListAction: "employerGetUserList",
    }),
    addMember() {
      this.$refs.memberDrawer.open();
    },
  },
};
</script>

<style>
.member-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px 20px 20px 50px;
  background-color: #fff;
  border-radius: 14px;
}
.member-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.member-title {
  font-size: 15px;
  font-weight: bold;
}
.member-main-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>