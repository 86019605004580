<template>
  <div class="employer-root-container">
    <div
      class="employer-background"
      :style="{'background-image':'url('+icons.employerBackground+')'}"
    ></div>
    <div class="employer-scroll-wrapper">
      <div class="employer-left-container">
        <employer-base />
        <service style="margin-top:60px;" />
      </div>
      <div class="employer-right-container">
        <employer-desc />
        <member style="margin-top:30px;" />
        <Divider style="margin:50px 0px" />
        <company />
      </div>
    </div>
  </div>
</template>

<script>
import EmployerBase from "./Panel/EmployerBase";
import Service from "./Panel/EmployerService";
import Member from "./Panel/EmployerMember";
import EmployerDesc from "./Panel/EmployerDesc";
import Company from "./Panel/EmployerCompany";
import * as eventHelper from "../../utils/eventHelper"
import * as icon from "../../common/icon";
export default {
  components: {
    "employer-base": EmployerBase,
    "employer-desc": EmployerDesc,
    service: Service,
    member: Member,
    company: Company,
  },
  data() {
    return {
      icons: icon,
    };
  },
  mounted(){
    eventHelper.triggerEvent('changeTitle',null)
  },
  methods: {
  },
};
</script>

<style scoped>
.employer-root-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px 115px 20px 115px;
  background-color: #f7f7f7;
}
.employer-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 340px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.employer-scroll-wrapper {
  padding-top: 150px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  z-index: 2;
}
.employer-left-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 340px;
  margin-right: 60px;
}
.employer-right-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
}
</style>